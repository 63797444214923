import React from "react";
import SiteHeader from "../components/SiteHeader";
import useFetch from "../hooks/useFetch";
import Blog from "./Blog";
import Video from "./Video";
import { Helmet } from "react-helmet";

export default function Page(properties) {
  const { loading, error, data } = useFetch("/aktuellpage");

  if (error) return <p>Error</p>;
  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 pt-lg-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  let content = data.data.attributes;

  return (
    <div>
      <Helmet>
      <meta name="description" content={content.metadesc}/>
      <meta property="og:title" content={content.metatitle} />
      <meta property="og:description" content={content.metadesc}/>
      <title>{content.metatitle}</title>
      </Helmet>
      <SiteHeader
        title={content.title}
        eyebrow={content.eyebrow}
        bgimage={`${content.bgimage}`}
      />
      <main className="container pt-lg-5 mt-lg-5">
        <div className="row mb-lg-5 pb-lg-5">
          <div className="col-lg-11 offset-lg-1 pb-lg-5">
            <div
              dangerouslySetInnerHTML={{
                __html: content.rawContent1?.replaceAll(
                  "/uploads",
                  process.env.REACT_APP_STRAPI_URL + "/uploads"
                ),
              }}
            />
            <Blog />
            <div
              dangerouslySetInnerHTML={{
                __html: content.rawContent2?.replaceAll(
                  "/uploads",
                  process.env.REACT_APP_STRAPI_URL + "/uploads"
                ),
              }}
            />
            <Video />
            <div
              dangerouslySetInnerHTML={{
                __html: content.rawContent3?.replaceAll(
                  "/uploads",
                  process.env.REACT_APP_STRAPI_URL + "/uploads"
                ),
              }}
            />
          </div>
        </div>
      </main>
    </div>
  );
}
