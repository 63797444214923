import React from 'react'
import { Link } from 'react-router-dom'
import useFetch from '../hooks/useFetch'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Video() {
  const slider = React.useRef(null);

  const { loading, error, data } = useFetch('/videos')

  if (loading) return <p></p>
  if (error) return <p></p>

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none"}}
        onClick={onClick}
      />
    );
  }

  let content = data.data;
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        }
      },
    ]
  };
  return (
    <div className='row mt-5'>
    <Slider ref={slider} {...settings} className="col-lg-11">
    {data.data.map(content =>(
  <div key={content.id} className="px-3 py-2">
  <div className="ratio ratio-16x9">
    <iframe
      src={content.attributes.video}
      title="YouTube video"
      allowFullScreen="allowfullscreen"
      className="rounded-4 shadow"
    ></iframe>
  </div>
  <h5 className="text-secondary fw-bold mt-3 mb-0">
    {content.attributes.title}
  </h5>
  <p className="text-secondary fw-light mb-0">
    {content.attributes.subtitle}
  </p>
</div>
  ))}
    </Slider>
    <div className="col-lg-1 d-flex align-items-center">
            <div className="d-flex d-lg-block px-lg-0 px-4 justify-content-between w-100">
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselBlog" data-bs-slide="prev" onClick={() => slider?.current?.slickPrev()}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselBlog" data-bs-slide="next" onClick={() => slider?.current?.slickNext()}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
    </div>
      
  )
}

