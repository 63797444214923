import React from "react";
import Accordion from "react-bootstrap/Accordion";
import useFetch from "../hooks/useFetch";

export default function Accordion1() {
  const { loading, error, data } = useFetch("/accordeons");

  if (loading) return <p></p>;
  if (error) return <p>Error</p>;

  let content = data.data;
  return (
    <div className="col-lg-5 px-lg-0">
      {data.data.map((content) => (
        <Accordion
          key={content.id}
          defaultActiveKey="0"
          className=" accordion-flush"
        >
          <Accordion.Item eventKey={content.id} className="border-0">
            <Accordion.Header>
              <p className="bg-transparent text-info fw-bold fs-5 mb-0">
                {content.attributes.title}
              </p>
            </Accordion.Header>
            <Accordion.Body>{content.attributes.body}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );
}
