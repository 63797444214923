import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/stable/symbol';
import 'core-js/stable/object';
import 'core-js/stable/function';
import 'core-js/stable/parse-int';
import 'core-js/stable/parse-float';
import 'core-js/stable/number';
import 'core-js/stable/math';
import 'core-js/stable/string';
import 'core-js/stable/date';
import 'core-js/stable/array';
import 'core-js/stable/regexp';
import 'core-js/stable/map';
import 'core-js/stable/weak-map';
import 'core-js/stable/set';

if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}
