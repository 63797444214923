import React from "react";
import SiteHeader from "../components/SiteHeader";
import useFetch from "../hooks/useFetch";
import { Helmet } from "react-helmet";
import Testimonial from "./Testimonial";

export default function Page(properties) {
  const { loading, error, data } = useFetch("/" + properties.alias);

  if (error) return <p>Error</p>;
  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5 pt-lg-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    setTimeout(function () {
      if (document.getElementById("contactform")) {
        var contactform = document.getElementById("contactform");

        if (!window.customContactForm) {
          if (document.getElementById("upload-file")) {
            let fileElem = (window.fileElem =
              contactform.querySelector("#upload-file"));
            const gallery = contactform.querySelector("#gallery");

            function previewFile(file) {
              if (file.size > 5242880) {
                alert("File size is too large. Please try again");
                fileElem.value = "";
                return;
              }

              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () => {
                if (
                  file.type === "image/jpeg" ||
                  file.type === "image/png" ||
                  file.type === "image/tif" ||
                  file.type === "application/pdf" ||
                  file.type === "image/gif"
                ) {
                  console.log(file.name);

                  const doc = document.createElement("li");
                  doc.classList.add("mb-2");

                  doc.innerHTML = `
                <li class="mb-2">
                  <a href="javascript:void(0)" class="text-info me-2 text-decoration-none" onclick="resetFile()">X</a>
                  <span>${file.name}</span>
                </li>
                `;
                  gallery.appendChild(doc);
                  console.log(file);

                  // const img = document.createElement("img");
                  // img.src = reader.result;
                  // gallery.appendChild(img);
                }
                // else {
                //   const doc = document.createElement("span");
                //   doc.innerHTML = "new added doc";
                //   gallery.appendChild(doc);
                // }
              };
            }

            window.resetFile = function () {
              fileElem.value = "";
              gallery.innerHTML = "";
              [...fileElem.files].forEach(previewFile);
            };

            fileElem.addEventListener("change", () => {
              gallery.innerHTML = "";
              [...fileElem.files].forEach(previewFile);
            });
          }

          contactform.addEventListener("submit", submitForm);

          let targetUrl = contactform.getAttribute("action");

          function submitForm(e) {
            e.preventDefault();

            var form = document.querySelector("#contactform");
            var formData = new FormData(form);
            let bodyData = formData;
            let headers = {};

            if (targetUrl === "/tests") {
              let bodyData_ = [
                ...Array.from(new Set([...formData.keys()])).map((e) => ({
                  [e]: formData.getAll(e),
                })),
              ];
              bodyData = {};

              for (let i of bodyData_) {
                let e = Object.entries(i)[0];
                bodyData[e[0]] = e[1].join(", ");
              }
              bodyData = JSON.stringify({ data: bodyData });
              headers["Content-Type"] = "application/json";
            }

            fetch(process.env.REACT_APP_STRAPI_URL + "/api" + targetUrl, {
              method: "POST", // or 'PUT'
              headers: headers,
              body: bodyData,
            })
              .then((response) => response.json())
              .then((data) => {
                window.location.href = "thankyou";
              })
              .catch((error) => {
                alert("Error:", error);
              });
          }
        }

        window.customContactForm = true;
      }

      if (document.getElementById("calculateForm")) {
        let form = document.getElementById("calculateForm");
        let failText = document.getElementById("failText");
        let successText = document.getElementById("successText");

        form.addEventListener(
          "change",
          function () {
            /* Reset values */
            failText.innerHTML = failText.dataset.default;
            successText.innerHTML = successText.dataset.default;

            /* Calculate age */
            let day = this.querySelector("#day").value,
              month = this.querySelector("#month").value,
              year = this.querySelector("#year").value;

            let selectedDay = month + " " + day + " " + year;

            if (getAge(selectedDay) >= 20) {
              failText.innerHTML =
                day + "/" + month + "/" + (parseInt(year) + 20);

              failText.parentNode.parentNode.style.display = "block";
              successText.parentNode.parentNode.style.display = "none";
            } else {
              document.getElementById("successText").innerHTML =
                day + "/" + month + "/" + (parseInt(year) + 20);

              failText.parentNode.parentNode.style.display = "none";
              successText.parentNode.parentNode.style.display = "block";
            }
          },
          false
        );

        function getAge(dateString) {
          let today = new Date(),
            birthDate = new Date(dateString),
            age = today.getFullYear() - birthDate.getFullYear(),
            m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          return age;
        }
      }

      if (document.getElementById("num1")) {
        function animateValue(obj, start, end, duration) {
          let startTimestamp = null;
          const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min(
              (timestamp - startTimestamp) / duration,
              1
            );
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
              window.requestAnimationFrame(step);
            }
          };
          window.requestAnimationFrame(step);
        }

        const num1 = document.getElementById("num1");
        animateValue(num1, 0, 130, 2000);

        const num2 = document.getElementById("num2");
        animateValue(num2, 0, 4500, 2000);

        const num3 = document.getElementById("num3");
        animateValue(num3, 0, 4, 800);

        const num4 = document.getElementById("num4");
        animateValue(num4, 0, 155, 2000);
      }
    }, 1000);
  }
  let content = data?.data?.attributes;
  if (!content) {
    content = {
      title: "",
      eyebrow: "",
      bgimage: "header-background-no-bg-image",
      rawContent: `<div class="text-center pt-5 pb-5 mb-5">
      <h3 class="text-info text-center fw-bold fs-4">Page is under construction</h3>
      <p class="text-secondary text-center fw-light mb-3 mb-lg-5">
      Please check us later.
      </p>

      <a href="./" class="btn btn-outline-info border-info-lighter fw-bold text-secondary border-2 rounded-3" type="submit">Go to Homepage</a>
    </div>`,
    };
  }

  return (
    <div>
       <Helmet>
      <meta name="description" content={content.metadesc}/>
      <meta property="og:title" content={content.metatitle} />
      <meta property="og:description" content={content.metadesc}/>
      <title>{content.metatitle}</title>
      </Helmet>
      <SiteHeader
        title={content.title}
        eyebrow={content.eyebrow}
        bgimage={`${content.bgimage}`}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: content.rawContent?.replaceAll(
            "/uploads",
            process.env.REACT_APP_STRAPI_URL + "/uploads"
          ),
        }}
      />
      { ["Bonität", "Verlustscheine","Über uns", "Solvency", "About us", "Loss certificate"].includes(content.eyebrow) ? 
        <Testimonial/> : null }
    </div>
  );
}
