import React from "react";
import Navtab from "./Navtab";
import SiteHeader from "../components/SiteHeader";
import useFetch from "../hooks/useFetch";
import { Helmet } from "react-helmet";
import Testimonial from "./Testimonial";


export default function Inkasso() {
  const { loading, error, data } = useFetch("/inkasso");

  if (error) return <p>Error</p>;
  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 pt-lg-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  let content = data.data.attributes;

  return (
    <div>
      <Helmet>
        <meta name="description" content={content.metadesc} />
        <meta property="og:title" content={content.metatitle} />
        <meta property="og:description" content={content.metadesc} />
        <title>{content.metatitle}</title>
      </Helmet>
      <SiteHeader
        title={content.title}
        eyebrow={content.eyebrow}
        bgimage={`${content.bgimage}`}
      />
      <main className="container pt-lg-5 mt-lg-5">
        <div className="row position-relative">
          <div className="col-lg-7 offset-xl-1 text-start">
            <div
              dangerouslySetInnerHTML={{
                __html: content.rawContent?.replaceAll(
                  "/uploads",
                  process.env.REACT_APP_STRAPI_URL + "/uploads"
                ),
              }}
            />
            <Navtab />
          </div>
          <div className="col-lg-4 ms-auto text-end ps-xl-5 pe-2">
            <div
              dangerouslySetInnerHTML={{
                __html: content.rawContent2?.replaceAll(
                  "/uploads",
                  process.env.REACT_APP_STRAPI_URL + "/uploads"
                ),
              }}
            />
          </div>
        </div>
      </main>
      <div
        dangerouslySetInnerHTML={{
          __html: content.rawContent3?.replaceAll(
            "/uploads",
            process.env.REACT_APP_STRAPI_URL + "/uploads"
          ),
        }}
      />
      <Testimonial />
    </div>
  );
}
