import React from "react";
import useFetch from "../hooks/useFetch";

export default function SiteFooter() {
  const { loading, error, data } = useFetch("/footer");

  if (error) return <p></p>;
  if (loading) return <p></p>;

  let content = data.data.attributes;

  return (
    <div>
      <footer className="text-start text-white mt-3">
        <div
          dangerouslySetInnerHTML={{
            __html: content.footer?.replace(
              "/uploads",
              process.env.REACT_APP_STRAPI_URL + "/uploads"
            ),
          }}
        />
        <div className="container">
          <div className="row">
            <nav className="col-lg-auto offset-lg-1">
              <div
                dangerouslySetInnerHTML={{
                  __html: content.footerend?.replace(
                    "/uploads",
                    process.env.REACT_APP_STRAPI_URL + "/uploads"
                  ),
                }}
              />
            </nav>
            <div className="col-lg-auto ms-lg-auto">
              <p className="mt-lg-3 mb-3 text-secondary fw-light">
                &copy; {new Date().getFullYear()} Creditrefom Luzern Vogel AG
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
