import React from "react";
import Tab from "react-bootstrap/Tab";
import useFetch from "../hooks/useFetch";
import Nav from "react-bootstrap/Nav";
export default function LoginTabs() {
    const { loading, error, data } = useFetch("/login-tabs");

    if (loading) return <p></p>
    if (error) return <p></p>

    let content = data.data;

    // const lang = window.localStorage.getItem("cf_language");
    // console.log("Lang:", lang);

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey={'1'}>
                <Nav variant="pills mb-3">
                    {data.data.map((content) => (
                        <Nav.Item key={content.id} className="mb-3">
                            <Nav.Link
                                className="me-3 fw-bold border border-info-lighter"
                                eventKey={`${content.id}`}
                            >
                                {content.attributes.title}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                <Tab.Content className=" mt-lg-5">
                    {data.data.map((content) => (
                        <Tab.Pane key={content.id} eventKey={`${content.id}`}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: content.attributes.rawContent,
                                }}
                            />
                        </Tab.Pane>
                    ))}
                </Tab.Content>
            </Tab.Container>
        </>
    );
}
