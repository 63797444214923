import React from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

const title = "";
const eyebrow = "";
const bgimage = "";
export default function SiteHeader(properties) {
  const { loading, error, data } = useFetch("/header");

  if (error) return <p></p>;
  if (loading) return <p></p>;
  const lang = window.localStorage.getItem("cf_language");
  console.log("Lang:", lang)
  let content = data.data.attributes;
  console.log(content);
  setTimeout(function () {
    if (document.getElementById("sticky-navbar")) {
      window.onscroll = function () {
        toggleNavbar();
      };

      // Get the navbar
      var navbar = document.getElementById("sticky-navbar");

      // Get the offset position of the navbar
      var sticky = navbar.offsetTop;

      // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
      function toggleNavbar() {
        if (window.pageYOffset >= 300) {
          navbar.classList.remove("noscrolled");
        } else {
          navbar.classList.add("noscrolled");
        }
      }
    }
  }, 500);

  return (
    <>
      <header className={`header-background pt-3 mb-4 ${properties.bgimage}`}>
        <div className="container">
          <Navbar
            expand="lg"
            className="bg-transparent navbar-light mb-0 py-0  mt-2 ps-0 d-block d-lg-none"
          >
            <Container>
              <Navbar.Brand className="pb-lg-0 pt-lg-3" href="/">
                <img
                  className="img-fluid col-lg-lg-8"
                  src="/assets/img/logo.svg"
                  alt="logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <div className="d-flex align-items-start align-items-lg-end w-100 pt-3 pt-lg-0">
                  <ul className="navbar-nav mb-2 mb-lg-0 gilroy-extrabold">
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/inkasso"
                      >
                        {content.inkasso}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/verlustscheine"
                      >
                        {content.verlustschein}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/bonitat"
                      >
                        {content.bonitat}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/aktuell"
                      >
                        {content.aktuell}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/uber-uns"
                      >
                        {content.uberUns}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/kontakt"
                      >
                        {content.kontakt}
                      </a>
                    </li>
                  </ul>

                  <ul className="navbar-nav mb-2 mb-lg-0 mx-auto gilroy-extrabold">
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/jobs"
                      >
                        {content.jobs}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/downloads"
                      >
                        {content.downloads}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        aria-current="page"
                        href="/inkassoformular"
                      >
                        {content.inkassoauftrag}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active text-secondary fw-bold py-lg-0"
                        href="/login"
                      >
                        {content.loginInkasso}
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <button
                        onClick={() => {
                          localStorage.setItem("cf_language", "en");
                          document.location.reload(true);
                        }}
                        className="nav-link active text-secondary fw-bold py-lg-0 bg-transparent border-0"
                      >
                        EN
                      </button>
                    </li> */}
                    <li className="nav-item">
                      <button
                        onClick={() => {
                          localStorage.setItem("cf_language", "de-CH");
                          document.location.reload(true);
                        }}
                        className="nav-link active text-secondary fw-bold py-lg-0 bg-transparent border-0"
                      >
                        DE
                      </button>
                    </li>
                  </ul>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <nav className="navbar navbar-expand-lg bg-transparent navbar-light mb-0 py-0  mt-2 ps-0 d-lg-flex d-none">
            <a className="navbar-brand pb-lg-0 pt-lg-3" href="/">
              <img
                className="img-fluid col-lg-lg-8"
                src="/assets/img/logo.svg"
                alt="logo"
              />
            </a>
            <button
              className="navbar-toggler text-secondary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#headerNavbar"
              aria-controls="headerNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <ul className="navbar-nav ms-auto d-lg-flex d-none">
              <li className="nav-item">
                <a
                  className="nav-link active text-secondary fw-light py-0"
                  aria-current="page"
                  href="/jobs"
                >
                  {content.jobs}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active text-secondary fw-light py-0"
                  aria-current="page"
                  href="/downloads"
                >
                  {content.downloads}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active text-secondary fw-light py-0"
                  aria-current="page"
                  href="/inkassoformular"
                >
                  {content.inkassoauftrag}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active text-secondary fw-light py-0"
                  href="/login"
                >
                  {content.loginInkasso}
                </a>
              </li>
              {/* <li className="nav-item">
                <button
                  onClick={() => {
                    localStorage.setItem("cf_language", "en");
                    document.location.reload(true);
                  }}
                  className="nav-link active text-secondary py-lg-0 bg-transparent border-0"
                >
                  EN
                </button>
              </li> */}
              <li className="nav-item">
                <button
                  onClick={() => {
                    localStorage.setItem("cf_language", "de-CH");
                    document.location.reload(true);
                  }}
                  className="nav-link active text-secondary py-lg-0 bg-transparent border-0"
                >
                  DE
                </button>
              </li>
            </ul>
          </nav>
          <nav className="navbar navbar-expand-lg bg-transparent py-0 h-auto d-lg-flex d-none">
            <div className="collapse navbar-collapse" id="headerNavbar">
              <div className="d-flex align-items-start align-items-lg-end w-100 pt-3 pt-lg-0">
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto gilroy-extrabold">
                  <li className="nav-item">
                    <a
                      className="nav-link active text-secondary fw-bold py-lg-0"
                      aria-current="page"
                      href="/inkasso"
                    >
                      {content.inkasso}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active text-secondary fw-bold py-lg-0"
                      aria-current="page"
                      href="/verlustscheine"
                    >
                      {content.verlustschein}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active text-secondary fw-bold py-lg-0"
                      aria-current="page"
                      href="/bonitat"
                    >
                      {content.bonitat}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active text-secondary fw-bold py-lg-0"
                      aria-current="page"
                      href="/aktuell"
                    >
                      {content.aktuell}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active text-secondary fw-bold py-lg-0"
                      aria-current="page"
                      href="/uber-uns"
                    >
                      {content.uberUns}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active text-secondary fw-bold py-lg-0"
                      aria-current="page"
                      href="/kontakt"
                    >
                      {content.kontakt}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className={lang == 'en' ? 'text-start my-5 col-xxl-6 col-lg-8' : 'text-start my-5 col-lg-6'}>
            <h4 className="fw-light text-info pt-3">{properties.eyebrow}</h4>
            <h1 className="fw-bold text-secondary display-5 pb-3">
              {properties.title}
            </h1>
          </div>
        </div>
      </header>

      <header
        id="sticky-navbar"
        className="header-background py-2 position-fixed d-none d-lg-block w-100 bg-opacity-info noscrolled"
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg bg-transparent">
            <a className="navbar-brand pt-0 pb-2" href="/">
              <img
                className="img-fluid"
                src="/assets/img/logo.svg"
                width="230"
                alt="logo"
              />
            </a>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ms-auto gilroy-extrabold pt-2 ">
                <li className="nav-item">
                  <a
                    className="nav-link active text-secondary fw-bold pb-0"
                    aria-current="page"
                    href="/inkasso"
                  >
                    {content.inkasso}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-secondary fw-bold pb-0"
                    aria-current="page"
                    href="/verlustscheine"
                  >
                    {content.verlustschein}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-secondary fw-bold pb-0"
                    aria-current="page"
                    href="/bonitat"
                  >
                    {content.bonitat}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-secondary fw-bold pb-0"
                    aria-current="page"
                    href="/aktuell"
                  >
                    {content.aktuell}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-secondary fw-bold pb-0"
                    aria-current="page"
                    href="/uber-uns"
                  >
                    {content.uberUns}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-secondary fw-bold pb-0"
                    aria-current="page"
                    href="/kontakt"
                  >
                    {content.kontakt}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
