import React from "react";
import SiteHeader from "../components/SiteHeader";
import useFetch from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BlogDetails(props) {
  const { id } = useParams();

  const { loading, error, data } = useFetch("/aktuells/" + id);

  if (error) return <p>Error</p>;
  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 pt-lg-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  let content = data.data.attributes;
  const lang = window.localStorage.getItem("cf_language");
  console.log("Lang:", lang)
  return (
    <div>
      <Helmet>
        <meta name="description" content={content.detailtitle} />
        <title>{content.detailtitle}</title>
      </Helmet>
      <SiteHeader
        title={content.detailtitle}
        eyebrow={content.detaileyebrow}
        bgimage="header-background-no-bg-image"
      />
      <main className="container">
        <div className="row mb-lg-5 pb-lg-5">
          <div className="col-lg-7 offset-lg-1 py-lg-5">
            <a href="/aktuell" className="text-secondary fw-bold d-flex">
              <span className=" fs-4 text-info me-1">&#8249;</span>
              <span className="align-self-center">{lang == "en" ? "back" : "zurück"}</span>
              </a>
            <div className="mt-3"
              dangerouslySetInnerHTML={{
                __html: content.details?.replaceAll(
                  '<img src="/uploads',
                  '<img class="img-fluid" src="' +
                  process.env.REACT_APP_STRAPI_URL +
                  "/uploads"
                ),
              }}
            />
          </div>
          {content.url && (
            <div className="col-lg-auto py-lg-5">
              <img src={`${content.url}`} className="img-fluid" alt="" />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
