import React from "react";
import useFetch from "../hooks/useFetch";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
export default function Testimonial() {
    const slider = React.useRef(null);

    const { loading, error, data } = useFetch("/testimonials");

    if (loading) return <p></p>;
    if (error) return <p></p>;

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    let content = data.data;
    // console.log("contnet:", content)
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,
                },
            },
        ],
    };
    const lang = window.localStorage.getItem("cf_language");
    console.log("Lang:", lang)

    data.data = data.data.sort(function (a, b) {
        if (a.attributes.index !== b.attributes.index) {
            return a.attributes.index - b.attributes.index;
        }
        return new Date(b.attributes.updatedAt) - new Date(a.attributes.updatedAt);
    });
    return (
        <div className="container ps-lg-5 px-0">
            <div className="row col-lg-11 ps-lg-4">
                <h2 className="text-secondary fw-bold my-3 my-lg-5 pt-lg-5 ms-lg-5 ms-3">{lang == "en" ? "What our customers say" : "Das sagen unsere Kunden"}</h2>
                <Slider ref={slider} {...settings} className="col-lg-10">
                    {data.data.map((content) => (
                        <div key={content.id} >
                            <div className="bg-white rounded-4 shadow mb-5 px-4 pt-4 pb-3 my-4 d-block d-md-flex mx-5">
                                <img src={process.env.REACT_APP_STRAPI_URL + content.attributes.url} className="rounded-circle mb-4 mb-md-0 ms-5 ms-md-0" height="128" />
                                <div className="ps-4 d-block ms-3 py-0">
                                    <p className="fw-light text-secondary">
                                        {content.attributes.testimonial}</p>
                                    <div className="mt-4 mb-0 lh-lg">
                                        <h6 className="text-secondary fw-bold">{content.attributes.name}</h6>
                                        <p className="fw-light text-secondary">{content.attributes.job}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="col-lg-1 d-flex align-items-center">
                    <div className="d-flex d-lg-block px-lg-0 px-4 justify-content-between w-100">
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselTestimonial"
                            data-bs-slide="prev"
                            onClick={() => slider?.current?.slickPrev()}
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselTestimonial"
                            data-bs-slide="next"
                            onClick={() => slider?.current?.slickNext()}
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
