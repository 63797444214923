import React from "react";
import SiteHeader from "../components/SiteHeader";
import useFetch from "../hooks/useFetch";
import Accordion1 from "./Accordion1";
import Accordion2 from "./Accordion2";

export default function Faq() {
  const { loading, error, data } = useFetch("/faq");

  if (error) return <p>Error</p>;
  if (loading)
    return (
      <div className="d-flex justify-content-center mt-5 pt-lg-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  let content = data.data.attributes;

  return (
    <>
      <SiteHeader
        title={content.title}
        eyebrow={content.eyebrow}
        bgimage={`${content.bgimage}`}
      />
      <main className="container pt-lg-5 mt-lg-5">
        <div className="row mb-lg-5 pb-lg-5">
          <div className="col-lg-9 offset-lg-1 pb-lg-5">
            <div
              dangerouslySetInnerHTML={{
                __html: content.rawContent?.replaceAll(
                  "/uploads",
                  process.env.REACT_APP_STRAPI_URL + "/uploads"
                ),
              }}
            />
            <div className="container px-lg-0">
              <div className="row px-lg-0">
                <Accordion1 />
                <Accordion2 />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
